document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('header'),
    animEl = document.querySelector('#squiggle animate');

  let mouseover = false;

  function onMouseEnter() {
    mouseover = true;
    animEl.beginElement();
  }

  function onMouseLeave() {
    mouseover = false;
  }

  function onRepeatEvent() {
    if (!mouseover) animEl.endElement();
  }

  container.addEventListener('mouseenter', onMouseEnter);
  container.addEventListener('mouseleave', onMouseLeave);
  animEl.addEventListener('repeatEvent', onRepeatEvent);
});
